<template>
  <v-container>
    <v-card class="px-7 py-9">
      <h1 class="mb-5 pl-3">{{ $t('components.termsOfOrderFulfillment.title') }}</h1>
      <ul>
        <li class="my-2 pb-2">{{ $t('components.termsOfOrderFulfillment.textOne') }}</li>
        <li class="my-2 pb-2">{{ $t('components.termsOfOrderFulfillment.textTwo') }}</li>
        <li class="my-2 pb-2">{{ $t('components.termsOfOrderFulfillment.textThree') }}</li>
        <li class="my-2 pb-2">{{ $t('components.termsOfOrderFulfillment.textFour') }}</li>
        <li class="my-2 pb-2">{{ $t('components.termsOfOrderFulfillment.textFive') }}</li>
      </ul>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'TermsOfOrderFulfillment',
};
</script>
